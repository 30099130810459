<template>
  <span>
    <template v-if="!short || !value">
      <template v-if="isCapitalized"
        >{{ capitalize(trans(`attributes.definition.${definition.code}`)) }} :</template
      >
      <template v-else>{{ trans(`attributes.definition.${definition.code}`) }} :</template>
    </template>
    <span v-if="value">
      {{ trans(`attributes.value.${value.value}`) }}
    </span>
    <span v-else-if="null !== booleanValue">
      {{ trans(`general.${booleanValue ? 'yes' : 'no'}`) }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    definition: { type: Object, required: true },
    value: { type: Object, required: false, default: null },
    booleanValue: { type: Boolean, required: false, default: null },
    short: { type: Boolean, required: false, default: false },
    isCapitalized: { type: Boolean, required: false, default: false },
  },
};
</script>
